:root {
  background-color: #111;
}

body {
  overscroll-behavior: none;
}

canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
}